import React, { useState } from 'react'
import './css/timeline.css'
import ToggleBox from './toggleBox'

export default function Timeline() {
    const [showExperience, setShowExperience] = useState(true)
    const [showSchool, setShowSchool] = useState(true)
    const [showProjects, setShowProjects] = useState(true)
    //const [showPersonalStuff, setShowPersonalStuff] = useState(false)
    //const [showExtraStuff, setShowExtraStuff] = useState(false)

    return (
        <div className="timeline-container">
            <div className="header">
                my timeline
            </div>
            <div className="toggle-section">
                <ToggleBox title="experience" handler={setShowExperience} isChecked={showExperience} />
                <ToggleBox title="school" handler={setShowSchool} isChecked={showSchool} />
                <ToggleBox title="projects" handler={setShowProjects} isChecked={showProjects} />
            </div>


        </div>
    )
}

// work experience, school, projects, perosnal stuff, extra stuff
// also have a legend for dots vs small rectrangels to show times between