import './App.css';
import { useEffect, useState } from 'react'
import AboutMe from './components/aboutMe'
import Overview from './components/overview'
import Timeline from './components/timeline'
import  { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './components/notFound';

export default function App() {
  useEffect(() => {
    document.title = "angel gutierrez 👽"
  }, [])
  
  const [isOverview, setIsOverview] = useState(true)
  const [isAboutMe, setIsAboutMe] = useState(false)
  const [isTimeline, setIsTimeline] = useState(false)

  function changeToOverview() {
    document.title = "angel | overview 🐱‍👤"
    setIsAboutMe(false)
    setIsTimeline(false)
    setIsOverview(true)
  }

  function changeToTimeline() {
    document.title = "angel | timeline ⌚"
    setIsAboutMe(false)
    setIsTimeline(true)
    setIsOverview(false)
  }

  function changeToAboutMe() {
    document.title = "angel | about me 👨‍💻"
    setIsAboutMe(true)
    setIsTimeline(false)
    setIsOverview(false)
  }

  return (
    <div className="big-container">
      <div className="navbar-section">
        <div className="name-part">
          angel gutierrez
        </div>
        <div className="about-me-part">
          { isOverview ? <u>overview</u> : <div onClick={changeToOverview}>overview</div>}
        </div>
        <div className="about-me-part">
          { isAboutMe ? <u>about me</u> : <div onClick={changeToAboutMe}>about me</div>}
        </div>
        <div className="timeline-part">
        { isTimeline ? <u>timeline</u> : <div onClick={changeToTimeline}>timeline</div>}
          
        </div>
      </div>
      <div className="timeline-section">
        <BrowserRouter>
          <Routes>
            <Route index element={<Overview />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/timeline" element={<Timeline />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className="footer">
        
      </div>
    </div>
  );
}
